import React, { ReactElement } from "react";
import { Box } from "@chakra-ui/react";
import { DesktopIntroduction, MobileIntroduction } from "../components/home/Introductions";
import { MyNavbar } from "../components/common/navbar/MyNavbar";

// Use tabs for sign in and sign out forms
export default function Home(): ReactElement {
  return (
    <Box>
      <Box minH="100vh" overflow="hidden">
        <MyNavbar />
        <Box display={{ base: "none", lg: "flex" }}>
          <DesktopIntroduction />
        </Box>
        <Box display={{ base: "flex", lg: "none" }}>
          <MobileIntroduction />
        </Box>
      </Box>
    </Box>
  );
}
